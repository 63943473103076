import React from "react"
import Nav from "../../components/nav.js"
import Lifestyle from "../ingredients/@laboratories.js"

export default function Laboratories_Nav() {
  return (
    <Nav>
      <Lifestyle />
    </Nav>
  )
}